import React from 'react';
import styled from 'styled-components';

const StyledHamburger = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  div {
    position: absolute;
    height: 2px;
    background: ${(props) => props.color};
    transition: 350ms ease-in-out;
    &:nth-child(1) {
      width: 100%;
      top: 15%;
    }
    &:nth-child(2) {
      width: 90%;
      top: 50%;
      left: 8%;
    }
    &:nth-child(3) {
      width: 100%;
      top: 85%;
    }
  }
  &.active {
    div {
      &:nth-child(1) {
        transform: rotateZ(45deg);
        width: 120%;
        top: calc(50% - 1px);
      }
      &:nth-child(2) {
        width: 90%;
        top: 50%;
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotateZ(-45deg);
        width: 120%;
        top: calc(50% - 1px);
      }
    }
  }
`;

type Props = {
  color: string;
  active: boolean;
  onClick?: () => void;
};

const Hamburger = ({ active, color, onClick }: Props) => {
  return (
    <StyledHamburger
      color={color}
      className={active ? 'active' : ''}
      onClick={onClick}
    >
      <div></div>
      <div></div>
      <div></div>
    </StyledHamburger>
  );
};

export default Hamburger;
