import React, { PropsWithChildren, useContext } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import Alert from './Alert';
import LinkPreventPopup from './LinkPreventPopup';
import LoaderOverlay from './LoaderOverlay';
import Navbar from './Navbar';
import MaintenanceOverlay from './MaintenanceOverlay';

const StyledLayout = styled.div<{ $clean?: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f2f2f2;
  .app-inner {
    &.left-space {
      margin-left: 200px;
    }
    flex-grow: 1;
    padding: ${({ $clean }) => ($clean ? ' 0' : ' 2rem')};
  }

  @media (max-width: 1350px) {
    @media (max-width: 1350px) {
      .single-customer-inner {
        flex-direction: column;
        .single-customer-left-content {
          width: 100%;
        }
        .single-customer-right-content {
          width: 100%;
        }
      }
      .app-inner.left-space {
        margin-left: 120px;
      }
    }
  }
  @media (max-width: 1000px) {
    .app-inner {
      padding: ${({ $clean }) => ($clean ? ' 0' : ' 1rem')};
      &.left-space {
        margin-left: 0;
        margin-top: 70px;
      }
    }
  }
`;

type Props = {
  clean?: boolean;
};

const Layout = (props: PropsWithChildren<Props>) => {
  const state = useContext(GlobalStateContext);
  let isLogin;
  if (typeof window !== `undefined`) {
    isLogin =
      window.location.pathname === '/' || window.location.pathname === '';
  }

  if (process.env.GATSBY_IS_MAINTENANCE === 'true') {
    console.error('IS MAINTENANCE', process.env.GATSBY_IS_MAINTENANCE);
    return <MaintenanceOverlay />;
  }

  return (
    <>
      <Alert />
      <LoaderOverlay />
      <Helmet>
        <title>Admin | H2o Teknik</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-16x16.png"
          sizes="16x16"
        />
      </Helmet>
      <StyledLayout $clean={props.clean}>
        {props.clean ? null : <Navbar />}
        {state.tokenVerified || isLogin ? (
          <div className={`app-inner ${props.clean ? '' : 'left-space'}`}>
            {props.children}
          </div>
        ) : null}
        <LinkPreventPopup />
      </StyledLayout>
    </>
  );
};

export default Layout;
