import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { colors } from '../utils/siteVars';
import BtnNoStyle from './BtnNoStyle';
import Plus from './Plus';
import { ActionType } from '../types/dispatch.types';

const StyledAlert = styled.div`
  position: fixed;
  top: -10rem;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  transition: 200ms ease-in-out;
  pointer-events: none;
  .alert-inner {
    margin-top: 1.5rem;
    background: ${colors.lightBlue};
    color: ${colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem 1rem 2rem;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
    p {
      margin: 0;
      margin-right: 2rem;
      pointer-events: none;
    }
    button {
      pointer-events: initial;
    }
  }
  &.active {
    top: 0;
  }
`;

const Alert = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const closeAlert = () => {
    dispatch({ type: ActionType.CLOSE_ALERT });
  };

  useEffect(() => {
    if (state.alert.active) {
      setTimeout(() => {
        closeAlert();
      }, 5000);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.alert]);

  return (
    <StyledAlert
      data-test="alert"
      className={state.alert.active ? 'active' : ''}
    >
      <div className="alert-inner">
        <p>{state.alert.content}</p>
        <BtnNoStyle onClick={closeAlert}>
          <Plus cross color={colors.white} />
        </BtnNoStyle>
      </div>
    </StyledAlert>
  );
};

export default Alert;
