import React, { useContext } from 'react';
import styled from 'styled-components';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import { colors } from '../utils/siteVars';
import Loader from './Loader';

const StyledLoaderOverlay = styled.div<{ $progress: number | null }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  pointer-events: none;
  transition: 200ms ease-in-out;
  z-index: 20;
  &.active {
    opacity: 1;
    pointer-events: initial;
  }
  .loading-bar-outer {
    width: 50vw;
    height: 1rem;
    background: ${colors.lightGrey};
    margin-top: 4rem;
    border-radius: 1rem;
    overflow: hidden;
    ${({ $progress }) => ($progress ? '' : 'display: none;')}
    .loading-bar-inner {
      transition: 200ms ease-in-out;
      width: ${({ $progress }) => $progress || 0}%;
      height: 100%;
      background: ${colors.lightBlue};
    }
  }
`;

const LoaderOverlay = () => {
  const { loading, loadingProgress } = useContext(GlobalStateContext);
  return (
    <StyledLoaderOverlay
      data-test="loader-overlay"
      className={loading ? 'active' : ''}
      $progress={loadingProgress}
    >
      <Loader color={colors.white} dualRing />
      <div className="loading-bar-outer">
        <div className="loading-bar-inner" />
      </div>
    </StyledLoaderOverlay>
  );
};

export default LoaderOverlay;
