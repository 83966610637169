import React from 'react';
import styled from 'styled-components';

const StyledMaintenanceOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background: white;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const MaintenanceOverlay = () => {
  return (
    <StyledMaintenanceOverlay>
      <div>
        <h1>Underhåll pågår</h1>
        <p>Kontakta patrik@bucketmedia.se för mer information</p>
      </div>
    </StyledMaintenanceOverlay>
  );
};

export default MaintenanceOverlay;
