import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledBtnNoStyle = styled.button`
  background: none;
  border: none;
  border-radius: 0;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  display: block;
  cursor: pointer;
  &:active,
  &:focus {
    outline: none;
  }
`;

type Props = {
  className?: string;
  onClick?: () => void;
};

const BtnNoStyle = ({
  className,
  onClick,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <StyledBtnNoStyle onClick={onClick} className={className}>
      {children}
    </StyledBtnNoStyle>
  );
};

export default BtnNoStyle;
